import React, { Component } from "react";

export default class Contact extends Component {
    render() {

        if (this.props.data) {
            var name = this.props.data.name;
            var phone = this.props.data.phone;
            var email = this.props.data.email;
            var message = this.props.data.contactmessage;

            var social = this.props.data.social.map(function (social) {
                return <div key={social.name} className="m-2 p-4"><a href={social.url}><i className={social.className + " text-light"}></i></a></div>
            })
        }

        return (
            <section id="contact" className="container-fluid vh-100 bg-dark text-white">

                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="d-flex flex-column justify-content-center h-75 text-center">
                        <h1 className="text-white fw-bolder display-3">Get in Touch.</h1>
                        <div className="text-white pt-2 mt-5 fs-2">
                            <p>{message}</p>
                        </div>

                        <div className="mt-5">
                            <a className="btn btn-lg px-5 btn-light text-dark-grey border border-3 rounded text-capitalize" href="mailto:shivanipatel937@gmail.com" >Email Me</a>
                        </div>
                    </div>


                    <div className="d-flex align-items-end h-25">
                        {social}
                    </div>
                    <div className="d-flex justify-content-center">
                        <p className="mb-2 font-weight-light text-white">&copy; Copyright 2022 Shivani Patel</p>
                    </div>

                </div>
            </section>
        );
    }
}
