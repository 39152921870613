import React, { Component } from "react";

class Header extends Component {
    render() {

        return (
            <header className="container">
                <div className="d-sm-grid flex-column h-100">
                    <div className="h-25">
                        <nav id="navbar" className="navbar navbar-expand-lg navbar-light" role="navigation">
                            <a className="navbar-brand" href="#"><img src="/images/logo.jpg" alt="SP Logo" width="80"></img></a>

                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                                <div id="nav" className="navbar-nav">
                                    <a className="nav-item nav-link active h2 smoothscroll" href="#home">Home</a>
                                    <a className="nav-item nav-link h2 smoothscroll" href="#about">About</a>
                                    <a className="nav-item nav-link h2 smoothscroll" href="#work">Works</a>
                                    <a className="nav-item nav-link h2 smoothscroll" href="#contact">Contact</a>
                                </div>
                            </div>
                        </nav>
                    </div>                
                </div>
            </header>
        );
    }
}

export default Header;