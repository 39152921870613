import React, { Component } from "react";

class About extends Component {
    render() {

        if (this.props.data) {
            // var name = this.props.data.name;
            var profilepic = "images/" + this.props.data.image;
            var bio1 = this.props.data.bio1;
            var bio2 = this.props.data.bio2;
            var bio3 = this.props.data.bio3;

            // var resumeDownload = this.props.data.resumeDownload;
            var backendSkills = this.props.data.skills.Backend.map(function (skill) {
                return <li key={skill.name}><em>{skill.name}</em></li>
            })
            var frontendSkills = this.props.data.skills.Frontend.map(function (skill) {
                return <li key={skill.name}><em>{skill.name}</em></li>
            })
        }

        return (
            <section id="about" className="vh-100 bg-dark text-white">
                <div className="content h-100">
                    <div className="d-flex justify-content-center">
                        <img className="profile-img rounded-circle" src={profilepic} alt="Shivani Patel Profile Pic" />
                    </div>
                    <div className="bio">
                        {/* <h2 className="text-white display-4">About Me</h2> */}

                        <div className="bio-text">
                            <p className="lh-2">{bio1}<br /><br />
                                {bio2}<br /><br />
                                {bio3}</p>
                        </div>

                        <div className="skill-set">
                            <div className="set">
                                <h3 className="text-white">Backend Skills</h3>
                                <ul className="skills">
                                    {backendSkills}
                                </ul>
                            </div>
                            <div className="set">
                                <h3 className="text-white">Frontend Skills</h3>
                                <ul className="skills">
                                    {frontendSkills}
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* <div className="">
                        <p>
                            <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Resume</a>
                        </p>
                    </div> */}
                </div>
            </section>
        );
    }
}

export default About;