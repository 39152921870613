import React, { Component } from "react";

class Resume extends Component {
    render() {

        if (this.props.data) {

            var work = this.props.data.work.map(function (work) {
                return <div className="project" key={work.company}><a href={work.url}><img src={"images/" + work.image} alt="Project" ></img></a>
                    <h3>{work.name}<span className="fs-6 fst-italic"> ({work.company})</span></h3>
                    <ul>{work.tools.map(function (tool) {
                        return <li>{tool.name}</li>
                    })}</ul>
                    <p className="work-description">{work.description}</p>
                </div>
            })
        }

        return (
            <section id="work" className="vh-100">
                <div className="content">
                    <h1>Projects</h1>

                    <div className="project-container h-100">
                        {work}
                    </div>
                </div>
            </section>
        );
    }
}

export default Resume;