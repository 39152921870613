import React, { Component } from "react";

class Landing extends Component {
    render() {

        if (this.props.data) {
            var name = this.props.data.name;
            var occupation = this.props.data.occupation;
            var description = this.props.data.description;
            var city = this.props.data.address.city;
            var country = this.props.data.address.country;
            var worklink = "#work";
        }

        return (
            <section className="landing vh-100">
                <div className="content h-75">
                    <div className="comp display-5">
                        <i class="fa-solid fa-laptop-code fa-4x"></i>
                    </div>
                    <div className="comp">
                        <h1 className="name sp-text-color mb-4 fw-bold"><small className="text-muted">Hello, I'm</small>&nbsp;{name}.</h1>
                        <h3 className="">A <small className="badge sp-bg-color">{occupation}</small> based in <span>{city}, {country}</span>.<br /> {description}.</h3>
                        <a type="button" href={worklink} className="btn btn-lg btn-light mt-3 text-dark-grey border border-3 rounded text-capitalize">View my work</a>
                    </div>
                </div>


                {/* <p className="scrolldown">
                    <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
                </p> */}

            </section>
        );
    }
}

export default Landing;